.section-map {
  background: #dce4ef url(../../images/bg-glow.png) no-repeat center top;
  padding-top: 24px;
  padding-bottom: 24px;
  overflow: hidden;
}

.text-center {
  text-align: center;
}

.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.nav:before,
.nav:after {
  /* content: " "; */
  display: table;
}

.nav:after {
  clear: both;
}

.nav > li {
  position: relative;
  display: block;
}

.nav > li > a {
  position: relative;
  display: block;
  padding: 9px 15px;
}

.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
  background-color: #edf1f7;
}

.nav > li.disabled > a {
  color: #666;
}

.nav > li.disabled > a:hover,
.nav > li.disabled > a:focus {
  color: #666;
  text-decoration: none;
  background-color: transparent;
  cursor: not-allowed;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: #edf1f7;
  border-color: #205493;
}

.nav .nav-divider {
  height: 1px;
  margin: 11px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.nav > li > a > img {
  max-width: none;
}

.nav-tabs {
  border-bottom: 1px solid #cbd7e7;
}

.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}

.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.5;
  border: 1px solid transparent;
  border-radius: 8px 8px 0 0;
}

.nav-tabs > li > a:hover {
  border-color: #cbd7e7 #cbd7e7 #cbd7e7;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #555;
  background-color: #fff;
  border: 1px solid #cbd7e7;
  border-bottom-color: transparent;
  cursor: default;
}

.data-nav-tabs {
  display: inline-block;
  vertical-align: top;
  background-color: #edf1f7;
  border: 1px solid #cbd7e7;
  box-shadow: inset 0 2px 0 #cbd7e7;
  border-radius: 24px;
  text-align: center;
  margin-bottom: 32px;
  padding-top: 9px;
  padding-bottom: 10px;
  padding-left: 9px;
  padding-right: 9px;
}

.data-nav-tabs > li {
  display: inline-block;
  vertical-align: top;
  float: none;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.data-nav-tabs > li > a {
  color: #205493;
  border-radius: 18px;
  margin-right: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  text-decoration: underline;
}

.data-nav-tabs > li > a:hover,
.data-nav-tabs > li > a:focus {
  background-color: transparent;
  border-color: transparent;
  color: #112e51;
  text-decoration: none;
}

.data-nav-tabs > li.active > a,
.data-nav-tabs > li.active > a:hover,
.data-nav-tabs > li.active > a:focus {
  border: 1px solid #bccbe0;
  box-shadow: 0 4px 0 #cfdae9;
  color: #112e51;
  text-decoration: none;
}

.data-nav-tabs-vspace-top {
  margin-top: 32px;
}

.count-title {
  color: #112e51;
  font-size: 52px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.5px;
  margin-bottom: 28px;
}

.count-subtitle {
  text-align: center;
  margin-top: -16px;
  margin-bottom: 16px;
}

path {
  pointer-events: all;
}
path:hover {
  opacity: 0.5;
  cursor: pointer;
}

.map_label {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-anchor: middle;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: 18px;
  line-height: normal;
  font-family: Arial;
}

.instruction_text {
  color: #323a45;
  /*font-family: 'Merriweather', serif;*/
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.instruction_text.mini {
  font-size: 16px;
}

.download-links {
  text-align: center;
  padding-bottom: 25px;
}
