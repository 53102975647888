.popup-funding-text {
  color: #323a45;
  font-family: 'Merriweather', serif;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
}

.popup-content {
  color: #323a45;
  font-family: 'Merriweather', serif;
  font-size: 15px;
  line-height: 100%;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 8px;
}

.popup-funding-text:after {
  display: block;
  clear: both;
  content: '';
  position: relative;
  left: 0;
  bottom: 0;
  max-width: 250px;
  height: 1px;
  width: 80%; /* or 100px */
  border-bottom: 2px solid #cecfdb;
  margin: 0 auto;
  padding: 4px 0px;
}
