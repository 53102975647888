// Paths to images and fonts relative from the SCSS entry point
$font-path: '~@cmsgov/design-system/dist/fonts';
$image-path: '~@cmsgov/design-system/dist/images';

// Core CMSDS styles
@import '@cmsgov/design-system/dist/scss/index';

body {
  background: #f0f0f0;
  color: #323a45;
  font-size: 1.6rem;
  font-family: inherit;
  margin: 0%;
  padding: 0;
}

.topic {
  font-family: 'Bitter', 'Georgia', 'Cambria', 'Times New Roman', 'Times', serif;
  color: #fff;
  font-size: larger;
  text-align: center;
  padding-top: 30px;
}

.container {
  background: linear-gradient(to bottom, #0071bb 0%, #112e51 63%);
}

.navStyle {
  list-style: none;
  display: inline-flex;
  justify-content: center;
  font-family: sans-serif;
  text-align: right;
}

.mainNav {
  list-style: none;
  display: inline-flex;
  text-align: left;
  font-size: large;
}

a {
  font-family: 'Bitter', 'Georgia', 'Cambria', 'Times New Roman', 'Times', serif;
  font-size: medium;
  color: #112e51;
  text-decoration: none;
}
.mainNav li {
  padding-right: 25px;
}

.mainNav a {
  font-family: 'Bitter', 'Georgia', 'Cambria', 'Times New Roman', 'Times', serif;
  color: #fff;
  text-decoration: none;
}

.labelClass {
  display: none;
}

.search {
  width: 100%;
  position: relative;
  display: flex;
  height: 40px;
}

.searchTerm {
  width: 100%;
  border: 3px solid #0a4b7f;
  border-right: none;
  padding: 5px;
  border-radius: 5px 0 0 5px;
  outline: none;
}

.searchTerm:focus {
  color: #00b4cc;
}

.searchButton {
  border: 1px solid #0a4b7f;
  background: #112e51;
  border-radius: 0 5px 5px 0;
  font-size: 16px;
  color: white;
  padding: 10px;
  font-family: 'Bitter', 'Georgia', 'Cambria', 'Times New Roman', 'Times', serif;
}

/*Resize the wrap to see the search bar change!*/
.wrap {
  width: 50%;
  position: relative;
  left: 48%;
  transform: translate(-50%, -50%);
  margin: 40px 0 0 0;
}
